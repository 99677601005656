import {Injectable} from '@angular/core';
import {IGood, ISurveyReport} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class MarketSurveyService {

    public currentSurvey: ISurveyReport = null;

    constructor(public http: HttpClient, private authService: AuthService,
                private router: Router) {
        if (!this.currentSurvey || this.currentSurvey == null) {
            const temporarySurvey = localStorage.getItem('temporary_survey');
            if (temporarySurvey !== null) {
                this.currentSurvey = JSON.parse(temporarySurvey);
                if (this.currentSurvey !== null) {
                    if (this.currentSurvey.structure !== null) {
                        this.currentSurvey.structure_ = JSON.parse(this.currentSurvey.structure);
                    }
                }
            }
        }
    }

    hasProductInCurrentSurvey(id: number): boolean {
        let found = false;
        if (this.currentSurvey) {
            if (this.currentSurvey.structure_ !== null) {
                this.currentSurvey.structure_.forEach((item) => {
                    if (item.item.id === id) {
                        found = true;
                    }
                });
            }
        }
        return found;
    }

    getCurrentLocalSurvey(): ISurveyReport {
        if (!this.currentSurvey) {
            this.reset();
        }

        return this.currentSurvey;
    }

    addToCurrentSurvey(good: IGood, markup: number) {
        if (!this.currentSurvey) {
            this.reset();
        }

        this.removeSimilarItemFromCurrentSurvey(good);
        this.removeFromCurrentSurvey(good);

        // console.log(good);
        this.currentSurvey.structure_.push({
            item: good,
            mark_up: markup,
            others: []
        });

        this.updateLocalSurveyDB();
    }

    updateLocalSurveyDB() {
        this.currentSurvey.structure = JSON.stringify(this.currentSurvey.structure_);
        const data = JSON.stringify(this.currentSurvey);
        localStorage.setItem('temporary_survey', data);
    }

    createNewSurveyRecord(report): Observable<any> {
        return this.http.post(Constants.url + 'survey-reports', report, Constants.getAuthHeaderOption());
    }

    hasItemInCurrentSurvey(id: number): boolean {
        let found = false;
        if (this.currentSurvey) {
            this.currentSurvey.structure_.forEach((item) => {
                if (item.item.all_good_id === id) {
                    found = true;
                }
            });
        }
        return found;
    }

    getCurrentSurvey(): Observable<any> {
        return this.http.get(Constants.url + 'survey-reports', Constants.getAuthHeaderOption());
    }

    public removeFromCurrentSurvey(good: IGood) {
        let count = 0, toRemove = -1;
        if (this.currentSurvey.structure_ !== null) {
            this.currentSurvey.structure_.forEach((survey) => {
                if (survey.item.id === good.id) {
                    toRemove = count;
                }
                count++;
            });
        }
        if (toRemove > -1) {
            this.currentSurvey.structure_.splice(toRemove, 1);
        }

        this.updateLocalSurveyDB();
    }

    public removeSimilarItemFromCurrentSurvey(good: IGood) {
        let count = 0, toRemove = -1;
        if (this.currentSurvey.structure_ !== null) {
            this.currentSurvey.structure_.forEach((survey) => {
                if (survey.item.all_good_id === good.all_good_id) {
                    toRemove = count;
                }
                count++;
            });
        }
        if (toRemove > -1) {
            this.currentSurvey.structure_.splice(toRemove, 1);
        }
    }

    getMarkup(id: number) {
        let found = 5;
        if (this.currentSurvey) {
            this.currentSurvey.structure_.forEach((item) => {
                if (item.item.id === id) {
                    found = item.mark_up;
                }
            });
        }
        return found;
    }

    searchSurvey(url, param) {
        if (url == null || url === '') {
            url = Constants.url + 'survey-reports/paged' + param;
        }

        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    reset() {
        this.currentSurvey = {
            title: '',
            description: '',
            structure: null,
            structure_: []
        };

        this.updateLocalSurveyDB();
    }

    getSurveyByID(id: number): Observable<any> {
        const url = Constants.url + 'survey-reports';
        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    approveSurvey(id: number): Observable<any> {
        return this.http.put(Constants.url + 'survey-reports/approve/' + id, Constants.getAuthHeaderOption());
    }

    deleteSurvey(id: any) {
        return this.http.delete(Constants.url + 'survey-reports/' + id, Constants.getAuthHeaderOption());
    }
}
