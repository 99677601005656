import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {AuthService} from './auth.service';
import {IInternalUser, IMinistry, IUser, PaginatedLog} from '../interfaces/interfaces';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserDataService {

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    getUsers(filter = ''): Observable<any> {
        return this.http.get(Constants.url + 'users/internal-users' + filter, Constants.getAuthHeaderOption());
    }

    getUsersNoPagination(filter): Observable<any> {
        return this.http.get(Constants.url + 'users/internal-users/single?search=' + filter, Constants.getAuthHeaderOption());
    }

    getPageUsers(): Observable<any> {
        const url = Constants.url + 'users/internal-users';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getUsersAtUrl(url: string): Observable<any> {
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    searchUsers(url, param) {
        if (url === '') {
            url = Constants.url + 'users/internal-users?' + param;
        }
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getInstitutions(): Observable<any> {
        return this.http.get(Constants.url + 'public/institution', Constants.getAuthHeaderOption());
    }

    getParastals(): Observable<any> {
        return this.http.get(Constants.url + 'public/parastatal', Constants.getAuthHeaderOption());
    }

    getUserByID(user_id: number): Observable<any> {
        return this.http.get(Constants.url + 'users/' + user_id, Constants.getAuthHeaderOption());
    }

    getCurrentlyLoggedInUser(): Observable<any> {
        return this.http.get(Constants.url + 'users/me', Constants.getAuthHeaderOption());
    }

    addUser(internalUser: IInternalUser): Observable<any> {
        return this.http.post(Constants.url + 'auth/internal/signup', internalUser, Constants.getAuthHeaderOption());
    }

    adminAddUser(internalUser: IInternalUser): Observable<any> {
        internalUser.password = 'password123';
        internalUser.password_confirmation = 'password123';
        return this.http.post(Constants.url + 'users/internal', internalUser, Constants.getAuthHeaderOption());
    }

    internalProfileUpdate(me: IUser): Observable<any> {
        return this.http.put(Constants.url + 'internal/account/update', me, Constants.getAuthHeaderOption());
    }

    deleteUser(user_id: number): Observable<any> {
        return this.http.delete(Constants.url + 'users/' + user_id, Constants.getAuthHeaderOption());
    }

    lockUser(user_id: number, reason: string): Observable<any> {
        return this.http.put(Constants.url + 'users/lock/' + user_id, JSON.stringify({reason: reason}), Constants.getAuthHeaderOption());
    }

    getMDARegistrationData(data: Array<string> = []) {
        return this.http.get(Constants.url + 'auth/internal/signup?with=' + data.join(','),
            data.length ? Constants.getAuthHeaderOption() : Constants.getOrdinaryHeaderOption());
    }

    getInternalUserData(user_id: number) {
        return this.http.get(Constants.url + 'users/internal/' + user_id, Constants.getAuthHeaderOption());
    }

    updateInternalUserData(user_id, data: any): Observable<any> {
        return this.http.put(Constants.url + 'users/internal/' + user_id, data, Constants.getAuthHeaderOption());
    }
}

