import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {IState, IMinistry} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MinistryManagementService {


    public ministries: IMinistry;
    public ministry: IMinistry[];
    public ministryType: any;

    constructor(private http: HttpClient, private authService: AuthService) {
    }


    getMinistries(search = ''): Observable<any> {
        return this.http.get(Constants.url + 'institution?search=' + search, Constants.getAuthHeaderOption());
    }

    getMinistryType(): Observable<any> {
        return this.http.get(Constants.url + 'ministry-types', Constants.getAuthHeaderOption());
    }


    getStates(): Observable<any> {
        return this.http.get(Constants.url + 'states');
    }

    getInstitutions(): Observable<any> {
        return this.http.get(Constants.url + 'institution', Constants.getAuthHeaderOption());
    }

    getMinistryByID(id: number, others = ''): Observable<any> {
        return this.http.get(Constants.url + 'institution/' + id + '?with=' + others, Constants.getAuthHeaderOption());
    }

    getParastatalByID(id: number): Observable<any> {
        return this.http.get(Constants.url + 'parastatal/' + id, Constants.getAuthHeaderOption());
    }

    addMinistry(ministry: IMinistry): Observable<any> {
        return this.http.post(Constants.url + 'institution', ministry, Constants.getAuthHeaderOption());
    }

    addParastatal(parastatal: IMinistry): Observable<any> {
        return this.http.post(Constants.url + 'parastatal', parastatal, Constants.getAuthHeaderOption());
    }

    updateMinistry(ministry: IMinistry): Observable<any> {
        return this.http.put(Constants.url + 'institution/' + ministry.id, ministry, Constants.getAuthHeaderOption());
    }

    updateParastatal(id, parastatal: IMinistry): Observable<any> {
        return this.http.put(Constants.url + 'parastatal/' + id, parastatal, Constants.getAuthHeaderOption());
    }

    deleteMinistry(id: number): Observable<any> {
        return this.http.delete(Constants.url + 'institution/' + id, Constants.getAuthHeaderOption());
    }

    deleteParastatal(id: number): Observable<any> {
        return this.http.delete(Constants.url + 'parastatal/' + id, Constants.getAuthHeaderOption());
    }
}

