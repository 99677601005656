import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import {AuthService} from './auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Constants} from '../app.constants';

// import _ from "lodash";


@Injectable()
export class SearchDataService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getGoodsForSearch(url: string, param: string) {
        if (url === '') {
            url = Constants.url + 'search/goods?' + param;
        }
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    detailedSearch(parameters: any = {}) {
        // Initialize Params Object
        let params = new HttpParams();
        if (parameters.name) {
            params = params.append('', parameters.name);
        }
        if (parameters.category1) {
            params = params.append('category1', parameters.category1);
        }
        if (parameters.state) {
            params = params.append('state', parameters.state);
        }
        if (parameters.vendor) {
            params = params.append('vendor', parameters.vendor);
        }
        if (parameters.min_price) {
            params = params.append('min_price', parameters.min_price);
        }
        if (parameters.max_price) {
            params = params.append('max_price', parameters.max_price);
        }

        // Make the API call using the new parameters.
        const url = Constants.url + 'goods?search' + params.toString();
        return this.http.get(url, Constants.getAuthHeaderOption());
    }
}
