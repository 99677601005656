import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import {ApprovalRequest, Approvals, TaskType} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ApprovalRequestService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getApprovalRequests(): Observable<any> {
        // const url = Constants.url+'faqs';
        return this.http.get(Constants.url + 'approval-requests', Constants.getAuthHeaderOption());
    }

    getApprovalRequestByID(id: number): Observable<any> {
        const url = Constants.url + 'approval-requests';

        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    addApprovalRequest(approval_request: ApprovalRequest): Observable<any> {
        const url = Constants.url + 'approval-requests';
        return this.http.post(url, approval_request, Constants.getAuthHeaderOption());
    }

    approveApproval(approval_request: ApprovalRequest): Observable<any> {
        const url = Constants.url + 'approval-details/approve';
        return this.http.put(url + '/' + approval_request.id, approval_request, Constants.getAuthHeaderOption());
    }

    disapproveApproval(approval_request: ApprovalRequest): Observable<any> {
        const url = Constants.url + 'approval-details/disapprove';
        return this.http.put(url + '/' + approval_request.id, approval_request, Constants.getAuthHeaderOption());
    }

    deleteApprovalRequest(id: number): Observable<any> {
        const url = Constants.url + 'approval-requests';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    getApprovals(): Observable<any> {
        return this.http.get(Constants.url + 'approvals', Constants.getAuthHeaderOption());
    }

    getTaskTypes(): Observable<any> {
        return this.http.get(Constants.url + 'task-types', Constants.getAuthHeaderOption());
    }

}
