import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DocumentsService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getDocuments(): Observable<any> {
        return this.http.get(Constants.url + 'documents', Constants.getAuthHeaderOption());
    }

    getDocumentTypes(): Observable<any> {
        return this.http.get(Constants.url + 'doc-types', Constants.getAuthHeaderOption());
    }

    getDocumentByID(id: number): Observable<any> {
        const url = Constants.url + 'documents';
        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

// addDocFormat(document: Document): Observable<any> {
// const url = Constants.url+'documents';
// return this.http.post(url, document, Constants.getAuthHeaderOption())
//   .map((res: Response) => {
//     const data = res.json();
//     console.log('Insert document format status ' + data.status);
//     return data;
//   })
//   .catch(this.handleError);
// }


// updateDocument(document: DocumentsFormat): Observable<any> {
// const url = Constants.url+'doc-formats'
// return this.http.put(url + '/' + document_format.id, document_format, Constants.getAuthHeaderOption())
//   .map((res: Response) => {
//     const data = res.json();
// //      console.log('Insert good status ' + data.Status);
//     return data;
//   })
//   .catch(this.handleError);
// }

    deleteDocument(path: string): Observable<any> {
        const url = Constants.url + 'documents/delete';
        return this.http.delete(url + '/' + path, Constants.getAuthHeaderOption());
    }

    downloadDocument(docurl: string): Observable<any> {
        const url = Constants.url + 'documents';
        return this.http.get(url + '/' + docurl, Constants.getAuthHeaderOption());
    }
}

