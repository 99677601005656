import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {Notification, PaginatedLog, InternalUser} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class NotificationsService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }


    getNotifications(url): Observable<any> {
        // const url = Constants.url+'faqs';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getUnreadCount(): Observable<any> {
        // const url = Constants.url+'faqs';
        return this.http.get(Constants.url + 'notifications/user/unread', Constants.getAuthHeaderOption());
    }

    getUsers(): Observable<any> {
        // const url = Constants.url+'faqs';
        return this.http.get(Constants.url + 'users/internal-users', Constants.getAuthHeaderOption());
    }

    search(terms: Observable<any>) {
        return terms.debounceTime(400)
            .distinctUntilChanged()
            .switchMap(term => this.searchEntries(term));
    }

    searchEntries(term) {
        return this.http.get(Constants.url + 'users/all?search=' + term, Constants.getAuthHeaderOption());
    }


    getNotificationsAtUrl(url: string): Observable<any> {
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    searchNotification(): Observable<any> {
        const url = Constants.url + 'notifications';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getNotificationByID(id: number): Observable<any> {
        const url = Constants.url + 'notifications';

        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }


    deleteNotification(id: number): Observable<any> {
        const url = Constants.url + 'notifications';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    getAllNotifications(term: string): Observable<any> {
        return this.http.get(Constants.url + 'notifications?search=' + encodeURI(term), Constants.getAuthHeaderOption());

    }

    addNotification(notifications): Observable<any> {
        const url = Constants.url + 'notifications';
        return this.http.post(url, notifications, Constants.getAuthHeaderOption());
    }

}
