import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {MessageTemplate} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class MessageTemplatesService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }


    getMessages(): Observable<any> {
        return this.http.get(Constants.url + 'message-templates ', Constants.getAuthHeaderOption());
    }

    getMessageByID(id: number): Observable<any> {
        const url = Constants.url + 'message-templates';

        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    updateMessage(message_template: MessageTemplate): Observable<any> {
        const url = Constants.url + 'message-templates';
        return this.http.put(url + '/' + message_template.id, message_template, Constants.getAuthHeaderOption());
    }

}
