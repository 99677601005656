import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceNotFoundComponent implements OnInit {

  @Input() message = 'Sorry, unable to fetch the requested record.';
  @Input() willShow = true;

  constructor() { }

  ngOnInit() {
  }

}
