import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

declare const Pusher: any;

@Injectable()
export class PusherService {

    pusher: any;
    channel: any;

    constructor(private http: HttpClient) {
        this.pusher = new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster,
            encrypted: true
        });
        this.channel = this.pusher.subscribe('chat-event-channel');
    }

    like(num_likes) {
        this.http.post('', {likes: num_likes}).subscribe(data => {
        });
    }

}
