import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {IGoodsCategory} from '../interfaces/interfaces';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class GoodsCategoryService {

    constructor(private http: HttpClient, private router: Router) {
    }

    getAllGoodsCategory(url, param) {
        if (url == null || url === '') {
            url = Constants.url + 'paged/goods-category' + param;
        }

        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getCategories(): Observable<any> {
        return this.http.get(Constants.url + 'goods-category', Constants.getAuthHeaderOption());
    }

    public addNew(item: IGoodsCategory): Observable<any> {
        return this.http.post(Constants.url + 'goods-category', JSON.stringify(item), Constants.getAuthHeaderOption());
    }

    public deleteCategories(item: number[]): Observable<any> {
        const items = {'items': item};
        return this.http.post(Constants.url + 'goods-category/delete', JSON.stringify(items), Constants.getAuthHeaderOption());
    }

    public deleteCategory(item: number): Observable<any> {
        return this.http.delete(Constants.url + 'goods-category/' + item, Constants.getAuthHeaderOption());
    }

    public getGoodsCategory(id): Observable<any> {
        return this.http.get(Constants.url + 'goods-category/' + id, Constants.getAuthHeaderOption());
    }

    public updateCategory(id, details: IGoodsCategory): Observable<any> {
        return this.http.put(Constants.url + 'goods-category/' + id, JSON.stringify(details), Constants.getAuthHeaderOption());
    }
}
