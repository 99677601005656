import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AuthService {

    public token = localStorage.getItem('token');

    public static getToken() {
        return Constants.getToken();
    }

    public static setToken(token) {
        Constants.setToken(token);
    }

    public static setClaim(claim) {
        Constants.setClaim(claim);
    }

    public static getAuthenticatedUser() {
        return Constants.getAuthenticatedUser();
    }

    public static getAuthenticatedUserRole() {
        const user = AuthService.getAuthenticatedUser();
        if (user !== undefined && user) {
            return user.r;
        }
        return '';
    }

    public static getAuthenticatedUserPermissions() {
        const user = AuthService.getAuthenticatedUser();
        if (user && user.hasOwnProperty('p')) {
            return user.p as Array<string>;
        }
        return [];
    }

    public static isUserAuthenticated() {
        return Constants.isUserAuthenticated();
    }

    public static setReturnUrl(url: string) {
        Constants.setReturnUrl(url);
    }

    static can(permission: string): boolean {
        const allPermissions = AuthService.getAuthenticatedUserPermissions();
        const cPerm = permission.split('|');
        // console.log(permission, index);
        // console.log(allPermissions);
        // console.log(cPerm);
        return allPermissions.filter(p => cPerm.indexOf(p) > -1).length > 0;
    }

    constructor(private http: HttpClient, private router: Router) {
    }

    resetPassword(data): Observable<any> {
        return this.http.post<any>(Constants.url + 'auth/password/recovery', data, Constants.getOrdinaryHeaderOption());
    }

    checkResetToken(resetToken): Observable<any> {
        return this.http.get(Constants.url + 'auth/password/reset/' + resetToken, Constants.getOrdinaryHeaderOption());
    }

    changePassword(data): Observable<any> {
        return this.http.post<any>(Constants.url + 'auth/password/new', data, Constants.getOrdinaryHeaderOption());
    }

    login(data: any): Observable<any> {
        return this.http.post<any>(Constants.url + 'auth/login', data, Constants.getOrdinaryHeaderOption());
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        let nextUrl = null;
        if (localStorage.getItem('nextReturnUrl')) {
            nextUrl = localStorage.getItem('nextReturnUrl');
        }

        const surveyList = localStorage.getItem('temporary_survey');
        localStorage.clear();
        if (surveyList != null) {
            localStorage.setItem('temporary_survey', surveyList);
        }
        if (nextUrl) {
            AuthService.setReturnUrl(nextUrl);
        }
        this.router.navigate(['/']);
    }

    activateUserAccount(token: string): Observable<any> {
        return this.http.get<any>(Constants.url + 'auth/activate/' + token, Constants.getOrdinaryHeaderOption());
    }

    getUserProfile(username: string = 'me'): Observable<any> {
        return this.http.get<any>(Constants.url + 'users/' + username, Constants.getOrdinaryHeaderOption());
    }

    static getReturnUrl(clearOld) {
        return Constants.getReturnUrl(clearOld);
    }
}
