import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';


@Injectable()
export class DashboardService {
    public url = 'api/users';

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    getData(): Observable<any> {
        return this.http.get(Constants.url + 'dashboard/data', Constants.getAuthHeaderOption());
    }
}

