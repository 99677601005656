import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    @Output() trigger: EventEmitter<string> = new EventEmitter();

    upload(id = '') {
        this.trigger.emit(id);
    }
}
