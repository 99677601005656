import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';
import {Observable} from 'rxjs/Rx';
import Echo from 'laravel-echo';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';

declare const Pusher: any;


@Injectable({
    providedIn: 'root'
})
export class ChatService {
    echo: any;
    pusher: any;

    constructor(private authService: AuthService, private http: HttpClient) {
        // Pusher.logToConsole = true;

        this.pusher = new Pusher(environment.pusher.key, {
            cluster: 'eu',
            forceTLS: true,
            authEndpoint: Constants.hostUrl + `/broadcasting/auth`,
            auth: {headers: {Authorization: `Bearer ${Constants.getToken()}`, Accept: 'application/json'}}
        });

        console.log(this.pusher);
        // this.echo = new Echo({
        //     broadcaster: 'pusher',
        //     key: environment.pusher.key,
        //     cluster: environment.pusher.cluster,
        //     encrypted: true,
        // });

        // this.echo.connector.pusher.config.authEndpoint = Constants.hostUrl + `/broadcasting/auth`;
        // this.echo.connector.pusher.config.auth = {
        //     headers: {Authorization: `Bearer ${Constants.getToken()}`, Accept: 'application/json'},
        // };
    }

    createGroup(data: { participants: Array<any>; group_name?: string; description?: string; }): Observable<any> {
        return this.http.post<any>(Constants.url + 'chats/group', data, Constants.getAuthHeaderOption());
    }

    fetchParticipants(search): Observable<any> {
        return this.http.get<any>(Constants.url + 'chats/group?search=' + search, Constants.getAuthHeaderOption());
    }

    fetchGroups() {
        return this.http.get<any>(Constants.url + 'chats/groups', Constants.getAuthHeaderOption());
    }

    sendMessage(message) {
        return this.http.post<any>(Constants.url + 'chats/messages', message, Constants.getAuthHeaderOption());
    }

    fetchMessages(groupId, url = '') {
        if (!url) {
            url = Constants.url + 'chats/messages/' + groupId;
        }
        return this.http.get<any>(url, Constants.getAuthHeaderOption()).pipe(
            map((res: any) => {
                res.data.map(c => c.time_created = new Date(c.time).getTime());
                return res;
            })
        );
    }

    checkPresence() {
        return this.http.get<any>(Constants.url + 'chats/presence', Constants.getAuthHeaderOption());
    }
}

