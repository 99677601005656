import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {IRatings} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class RatingsService {
    public ratings: IRatings;
    public rating: IRatings[];

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getReview(): Observable<any> {
        const url = Constants.url + 'goods-review';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    insertReview(review: IRatings): Observable<any> {
        const url = Constants.url + 'goods-review';
        return this.http.post(url, review, Constants.getAuthHeaderOption());
    }

}
