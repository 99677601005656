import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatherModule} from 'angular-feather';
import {Smile} from 'angular-feather/icons';

const icons = {
    Smile
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FeatherModule.pick(icons)
    ],
    exports: [FeatherModule]
})
export class IconsModule {
}
