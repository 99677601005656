import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-small-loader',
  templateUrl: './small-loader.component.html',
  styleUrls: ['./small-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmallLoaderComponent implements OnInit {

  @Input() message = '';

  constructor() { }

  ngOnInit() {
  }

}
