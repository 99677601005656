import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {DocumentType} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class DocumentTypeService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getDocumentTypes(): Observable<any> {
        return this.http.get(Constants.url + 'doc-types', Constants.getAuthHeaderOption());
    }

    getDocumentTypeByID(id: number): Observable<any> {
        const url = Constants.url + 'doc-types'
        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    addDocType(document_type: DocumentType): Observable<any> {
        const url = Constants.url + 'doc-types';
        return this.http.post(url, document_type, Constants.getAuthHeaderOption());
    }


    updateDocumentType(document_types: DocumentType): Observable<any> {
        const url = Constants.url + 'doc-types';
        return this.http.put(url + '/' + document_types.id, document_types, Constants.getAuthHeaderOption());
    }

    deleteDocumentType(id: number): Observable<any> {
        const url = Constants.url + 'doc-types';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }
}
