import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';
import {PaginatedLog} from '../Interfaces/interfaces';

@Injectable()
export class ApprovalDetailService {
    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getApprovalDetail(): Observable<any> {
        return this.http.get(Constants.url + 'approval-details', Constants.getAuthHeaderOption());
    }

    addApprovalDetail(approval_detail): Observable<any> {
        const url = Constants.url + 'approval-details/create';
        return this.http.post(url, approval_detail, Constants.getAuthHeaderOption());
    }

    public addNew(item): Observable<any> {
        return this.http.post(Constants.url + 'approval-details/create', item, Constants.getAuthHeaderOption());
    }

    getApprovalDetailByID(id: number): Observable<any> {
        const url = Constants.url + 'approval-details/show';
        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    updateApprovalDetail(approval_detail): Observable<any> {
        const url = Constants.url + 'approval-details/update';
        return this.http.put(url + '/' + approval_detail.id, approval_detail, Constants.getAuthHeaderOption());
    }

    deleteApprovalDetail(id: number): Observable<any> {
        const url = Constants.url + 'approval-details/delete';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    getApprovals(): Observable<any> {
        return this.http.get(Constants.url + 'approvals', Constants.getAuthHeaderOption());
    }

    getInternalUsers(): Observable<any> {
        const url = 'users/all?search=';
        return this.http.get(Constants.url, Constants.getAuthHeaderOption());
    }

    searchEntries(term) {
        return this.http.get(Constants.url + 'users/all?search=' + term, Constants.getAuthHeaderOption());
    }

    // getUsers(query: string): Observable<any> {

    //   return this.http.get(Constants.url + 'users/all?search='+ query, Constants.getAuthHeaderOption())
    //     .map((response: Response) => <any>response.json().users)
    //     .do(data => console.log(data))
    //     .catch(this.handleError);
    // }

    search_word(term) {
        return this.http.get(Constants.url + 'users/all?search=' + term, Constants.getAuthHeaderOption());
    }

    getPageUsers(): Observable<any> {
        const url = Constants.url + 'users/internal-users';
        return this.http.get<PaginatedLog>(url, Constants.getAuthHeaderOption())
    }

    getUsersAtUrl(url: string): Observable<any> {
        return this.http.get<PaginatedLog>(url, Constants.getAuthHeaderOption());
    }
}
