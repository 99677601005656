import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import {AuthService} from './auth.service';
import {IUser, IVendor, IWarehouse, PaginatedLog} from '../interfaces/interfaces';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class VendorDataService {
    public url = 'api/users';

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    getVendors(): Observable<any> {
        return this.http.get(Constants.url + 'users/vendors', Constants.getAuthHeaderOption());
    }

    getVendorsNoPagination(filter = ''): Observable<any> {
        return this.http.get(Constants.url + 'users/vendors/single?search=' + filter, Constants.getAuthHeaderOption());
    }

    searchVendors(url, params) {
        if (url === '') {
            url = Constants.url + 'users/vendors?' + params;
        }
        return this.http.get(url, Constants.getAuthHeaderOption());

    }

    getStates(): Observable<any> {
        return this.http.get(Constants.url + 'states', Constants.getAuthHeaderOption());
    }

    deleteWarehouse(id: number): Observable<any> {
        return this.http.delete(Constants.url + 'warehouses/' + id, Constants.getAuthHeaderOption());
    }

    getWarehousesWithParam(param): Observable<any> {
        return this.http.get(Constants.url + 'warehouses' + param, Constants.getAuthHeaderOption());
    }

    getWarehouseByID(id: number): Observable<any> {
        return this.http.get(Constants.url + 'warehouses/' + id, Constants.getAuthHeaderOption());
    }

    getVendorByID(id: number): Observable<any> {
        return this.http.get(Constants.url + 'users/vendors/' + id, Constants.getAuthHeaderOption());
    }

    addVendor(vendor: IVendor): Observable<any> {
        return this.http.post(Constants.url + 'auth/vendor/signup', vendor, Constants.getAuthHeaderOption());
    }

    fetchVendorDataFromBPPApi(rcNumber): Observable<any> {
        return this.http.get(Constants.url + 'registration/bpp-api/' + rcNumber,
            Constants.getAuthHeaderOption());
    }

    addWarehouse(data): Observable<any> {
        return this.http.post(Constants.url + 'warehouses', data, Constants.getAuthHeaderOption());
    }

    adminAddVendor(vendor: IVendor): Observable<any> {
        return this.http.post(Constants.url + 'users/vendors', vendor, Constants.getAuthHeaderOption());
    }

    updateWarehouse(warehouse: IWarehouse): Observable<any> {
        return this.http.put(Constants.url + 'warehouses/' + warehouse.id, warehouse, Constants.getAuthHeaderOption());
    }

    updateVendor(vendor: IVendor): Observable<any> {
        return this.http.put(Constants.url + 'users/vendors/' + vendor.user_id, vendor, Constants.getAuthHeaderOption());
    }

    vendorProfileUpdate(me: IUser): Observable<any> {
        return this.http.put(Constants.url + 'vendor/account', me, Constants.getAuthHeaderOption());
    }

    deleteVendor(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id);
    }

    IfExists(field: string, value: string): Observable<any> {
        return this.http.get(Constants.url + 'auth/is-taken/' + field + '/' + value, Constants.getAuthHeaderOption());
    }
}

