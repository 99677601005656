import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import {TaskType} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class TaskTypeService {
    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getTaskTypes(): Observable<any> {
        return this.http.get(Constants.url + 'task-types', Constants.getAuthHeaderOption());
    }

    getTaskTypeByID(id: number): Observable<any> {
        const url = Constants.url + 'task-types'
        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    addTaskType(task_type: TaskType): Observable<any> {
        const url = Constants.url + 'task-types';
        return this.http.post(url, task_type, Constants.getAuthHeaderOption());
    }

    updateTaskType(task_types: TaskType): Observable<any> {
        const url = Constants.url + 'task-types';
        return this.http.put(url + '/' + task_types.id, task_types, Constants.getAuthHeaderOption());
    }

    deleteTaskType(id: number): Observable<any> {
        const url = Constants.url + 'task-types';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

}
