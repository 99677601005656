import Swal from 'sweetalert2';
import {HttpHeaders} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isArray} from 'rxjs/internal-compatibility';
import {error} from '@angular/compiler/src/util';
import {Router} from '@angular/router';

const helper = new JwtHelperService();

export class Constants {
    public static tokenName = 'token';
    public static claimName = 'claim';
    public static hostUrl = 'http://192.168.137.1:8000';
    public static production = '/api/';
    public static local = Constants.hostUrl + '/api/';
    public static url = Constants.local;
    public static GOOGLE_MAP_API_KEY = 'AIzaSyApVJI8RrUJg7UsWeja6ZSq7v3g-n-fWTQ';
    // public static bppApiKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjM2ODU2NDg5MTYsImlhdC
    // I6MTUzODE2NTI2OSwidXNlcm5hbWUiOiJlMTQ1MDk3YS1iYzk0LTRhN2MtODM4ZS02YTlkOTIxOTJmYmUifQ.3_yYP9ZsJUTXb5gaA8N7
    // K3E-6LGTuzlguK-XspCOH2I';
    // public static bppApiUrl = 'http://federalcontractors.bpp.gov.ng/api/v1/contractors/?';
    // public static bppApiUrl = Constants.url + 'mock-bpp-api?';

    public static setReturnUrl(url: string) {
        localStorage.setItem('nextReturnUrl', url);
    }

    public static grabRandomID() {
        return `${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 10000)}`;
    }

    public static getReturnUrl(clearOld) {
        const nextReturnUrl = localStorage.getItem('nextReturnUrl');
        if (clearOld) {
            localStorage.removeItem('nextReturnUrl');
        }

        let url = '/app/dashboard';

        if (nextReturnUrl && nextReturnUrl !== 'null' && nextReturnUrl !== 'undefined' && nextReturnUrl !== '/') {
            url = nextReturnUrl;
        }
        return url;
    }

    public static setToken(token) {
        localStorage.setItem(Constants.tokenName, token);
    }

    public static setClaim(claim) {
        claim = typeof claim === 'string' ? claim : JSON.stringify(claim);
        localStorage.setItem(Constants.claimName, claim);
    }

    public static isUserAuthenticated() {
        return !helper.isTokenExpired(Constants.getToken());
    }

    public static getToken() {
        return localStorage.getItem(Constants.tokenName);
    }

    public static getAuthenticatedUser() {
        const claim = localStorage.getItem(Constants.claimName);
        if (claim) {
            try {
                return JSON.parse(claim);
            } catch (e) {
            }
        }

        return {
            r: '', p: [], d: '', n: '', i: false
        };
    }


    private static extractError(err, router) {
        console.log(err);
        if (!err) {
            return 'Unknown error';
        }

        if (err.error) {
            console.log(err.error);
            if ((typeof err.error) === 'string') {
                try {
                    const errorParsed = JSON.parse(err.error);
                    if (errorParsed) {
                        err.error = errorParsed;
                    }
                } catch (e) {
                }
            }
            if (err.error.error) {
                if (err.error.error.errors) {
                    const errors = [];
                    Object.keys(err.error.error.errors).forEach(key => {
                        const data = err.error.error.errors[key];
                        if (isArray(data)) {
                            data.forEach(d => errors.push(`<div>${d}</div>`));
                        } else {
                            errors.push(data.toString());
                        }
                    });

                    return errors.join('');
                }
                if (err.error.error.message) {
                    return err.error.error.message;
                }
            }

            if (err.error.message) {
                return err.error.message;
            }
        }

        console.log(err);

        return err.toString();

    }

    public static handleError(err: any, router: Router, showError = false) {
        const data = Constants.extractError(err, router);
        if (showError) {
            Swal.fire({title: 'Error', html: data, type: 'error'});
        }

        if (data.toLowerCase() === 'token has expired') {
            Constants.setReturnUrl(location.href);
            router.navigate(['/']);
        }

        return data;
    }

    static getAuthHeaderOption() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + localStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    static getUploadHeader() {
        return new HttpHeaders({
            'authorization': 'Bearer ' + localStorage.getItem('token'),
        });
    }

    static getRawDataHeaderOption() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + localStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    static getOrdinaryHeaderOption() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    static succeeded(message: any, title = 'Successful!') {
        return Swal.fire(title, message, 'success');
    }

    static error(message: any, title = 'Error') {
        return Swal.fire(title, message, 'error');
    }

    static processPrint(element) {
        let html = '<html><body>';
        if (document.getElementsByTagName != null) {
            const headTags = document.getElementsByTagName('head');
            if (headTags.length) {
                html += headTags[0].innerHTML;
            }
        }
        html += `<style>.hide-on-print { display: none;} .show-on-print { display: block;}</style>` +
            `</head><body class="no-padding-on-print" style="background-color: white; height: auto;">${element}</body></html>`;
        const printWin = window.open('', 'processPrint');
        printWin.document.open();
        printWin.document.write(html);
        printWin.document.close();
        setTimeout(() => {
            printWin.print();
            printWin.close();
        }, 2000);
    }

    static confirm(data, callback) {
    }
}
