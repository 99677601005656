import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResourceNotFoundComponent} from './resource-not-found/resource-not-found.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ErrorDisplayComponent } from './error-display/error-display.component';

@NgModule({
    declarations: [ResourceNotFoundComponent, ErrorDisplayComponent],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [ResourceNotFoundComponent, ErrorDisplayComponent]
})
export class CustomErrorModule {
}
