import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'

import 'rxjs/add/operator/do';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PaginationsService {

  constructor(public http: HttpClient, private authService: AuthService) {
  }

}
