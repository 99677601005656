import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader/loader.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SmallLoaderComponent } from './small-loader/small-loader.component';
import { BigLoaderComponent } from './big-loader/big-loader.component';

@NgModule({
    declarations: [LoaderComponent, SmallLoaderComponent, BigLoaderComponent],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [LoaderComponent, SmallLoaderComponent, BigLoaderComponent]
})
export class LoaderModule {
}
