import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Constants} from '../../app.constants';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
    public router: Router;
    public form: FormGroup;
    public username: AbstractControl;
    public password: AbstractControl;

    public loading = false;
    public errorMessage = '';

    constructor(
        router: Router,
        fb: FormBuilder,
        public authService: AuthService,
    ) {
        this.router = router;
        this.form = fb.group({
            'username': ['', Validators.compose([Validators.required])],
            'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
        });

        this.username = this.form.controls['username'];
        this.password = this.form.controls['password'];

        if (Constants.isUserAuthenticated()) {
            const url = AuthService.getReturnUrl(true);
            this.router.navigate([url]);
        }
    }

    public onSubmit(values: Object): void {
        this.loading = true;
        this.errorMessage = '';
        this.authService.login(values).subscribe(result => {
                if (result && result.token && result.claim) {
                    const url = AuthService.getReturnUrl(true);
                    AuthService.setToken(result.token);
                    AuthService.setClaim(result.claim);
                    console.log(url);
                    location.href = url;
                }
                this.loading = false;
            }, (err) => {
                this.loading = false;
                this.errorMessage = Constants.handleError(err, this.router);
            }
        );
    }

    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }

}

// export function usernameValidator(control: FormControl): { [key: string]: any } {
//     var usernameRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
//     if (control.value && !usernameRegexp.test(control.value)) {
//         return {invalidEmail: true};
//     }
// }
