import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/toPromise';
import {IComment, IGood, IInternalUser, ISurveyReport, PaginatedLog} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SurveyService {
    public currentSurvey: ISurveyReport = null;
    public allSurveys: ISurveyReport[] = [];


    constructor(public http: HttpClient, private authService: AuthService) {
        const rawSurvey = localStorage.getItem('currentSurvey');
        if (rawSurvey) {
            this.currentSurvey = JSON.parse(rawSurvey);
        } else {

        }
    }

    searchSurveys(from: string, to: string) {
        const params = new URLSearchParams();
        params.append('from', from);
        params.append('to', to);
        return this.http.get(Constants.url + 'survey-reports?search=&' + params, Constants.getAuthHeaderOption());
    }

    getPageSurveys(): Observable<any> {
        const url = Constants.url + 'survey-reports';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getSurveysAtUrl(url: string): Observable<any> {
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    addSurvey(reports: ISurveyReport): Observable<any> {
        return this.http.post(Constants.url + 'survey-reports', reports, Constants.getAuthHeaderOption());
    }

    public addComment(item: IComment): Observable<any> {
        return this.http.post(Constants.url + 'comments', JSON.stringify(item), Constants.getAuthHeaderOption());
    }

    deleteRole(name: string): Observable<any> {
        const url = Constants.url + 'role';
        return this.http.delete(url + '/' + name, Constants.getAuthHeaderOption());
    }

    approveSurvey(id: number): Observable<any> {
        return this.http.put(Constants.url + 'survey-reports/approve/' + id, Constants.getAuthHeaderOption());
    }

    getGoods(): Observable<any> {
        const url = Constants.url + 'goods';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getUserInformation(user_id: number): Observable<any> {
        return this.http.get(Constants.url + 'users/' + user_id, Constants.getAuthHeaderOption());
    }

    // getSurveys(): Observable<any> {
    //     const url = Constants.url;
    //     // +'survey-reports'
    //     return this.http.get(url + 'survey-reports', Constants.getAuthHeaderOption()).map((res: Response) => {
    //         return res.json().data;
    //     }).catch(this.handleError);
    // }

    getSurveys(): Observable<any> {
        const url = Constants.url;
        // +'survey-reports'
        return this.http.get(url + 'survey-reports', Constants.getAuthHeaderOption());
    }

    searchSurvey(search): Observable<any> {
        const url = Constants.url;
        // +'survey-reports'
        return this.http.get(url + 'survey-reports?search=' + encodeURI(search), Constants.getAuthHeaderOption());
    }

    getSurveyByUserID(user_id: number): Observable<any> {
        return this.http.get(Constants.url + user_id + '/survey-reports', Constants.getAuthHeaderOption());
    }

    getAllSurveys(term: string): Observable<any> {
        return this.http.get(Constants.url + 'survey-reports?search=' + encodeURI(term), Constants.getAuthHeaderOption());
    }


    getUserSurveys(id: number): Observable<any> {
        const url = Constants.url;
        // +'survey-reports'
        return this.http.get(url + id + '/' + 'survey-reports', Constants.getAuthHeaderOption());
    }


    deleteSurvey(id: number): Observable<any> {
        const url = Constants.url + 'survey-reports'
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    RemoveSurvey(id) {
        return this.http.delete(Constants.url + 'survey-reports' + '/' + id, Constants.getAuthHeaderOption());
    }
}
