import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {Grades} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class GradeService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }


    getGrades(search?: string): Observable<any> {
        let query = '';
        if (search) {
            query += '?search=' + search
        }
        return this.http.get(Constants.url + 'grades' + query, Constants.getAuthHeaderOption());
    }

    addGrade(grade: Grades): Observable<any> {
        const url = Constants.url + 'grades';
        return this.http.post(url, grade, Constants.getAuthHeaderOption());
    }

    getGradeByID(id: number): Observable<any> {
        const url = Constants.url + 'grades';

        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    updateGrade(grade: Grades): Observable<any> {
        const url = Constants.url + 'grades';
        return this.http.put(url + '/' + grade.id, grade, Constants.getAuthHeaderOption());
    }

    deleteGrade(id: number): Observable<any> {
        const url = Constants.url + 'grades';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

}
