import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Constants} from '../app.constants';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean> | boolean {
        if (Constants.isUserAuthenticated()) {
            return true;
        }

        Constants.setReturnUrl(location.href);
        // navigate to login page
        this.router.navigate(['/']);
        return false;
    }
}
