import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {IRoles, IPermission} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RolesService {

    public roles: IRoles;
    public role: IRoles[];

    constructor(public http: HttpClient, private authService: AuthService) {
    }


    getRoles(search = ''): Observable<any> {
        const url = Constants.url + 'roles?search=' + search;
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getAllRoles(term: string): Observable<any> {
        return this.http.get(Constants.url + 'role?search=' + encodeURI(term), Constants.getAuthHeaderOption());
    }

    getPermissions(): Observable<any> {
        const url = Constants.url + 'permissions';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }


    getPermissionByID(): Observable<any> {
        const url = Constants.url + 'roles';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getRole(name: string): Observable<any> {
        const url = Constants.url + 'roles';
        return this.http.get(url + '/' + name, Constants.getAuthHeaderOption());
    }

    getRolePerm(name: string): Observable<any> {
        const url = Constants.url + 'roles';
        return this.http.get(url + '/' + name, Constants.getAuthHeaderOption());
    }


    RemoveRole(id) {
        return this.http.delete(Constants.url + 'roles' + '/' + id, Constants.getAuthHeaderOption());
    }

    insertRole(role): Observable<any> {
        const url = Constants.url + 'roles';
        return this.http.post(url, role, Constants.getAuthHeaderOption());
    }

    updateRole(name, role): Observable<any> {
        const url = Constants.url + 'roles/';
        return this.http.put(url + name, role, Constants.getAuthHeaderOption());
    }

    deleteRole(name: string): Observable<any> {
        const url = Constants.url + 'roles';
        return this.http.delete(url + '/' + name, Constants.getAuthHeaderOption());
    }

}
