import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import {IGood} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class VendorGoodsService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    getIAllGoods(search?: string): Observable<any> {
        let url;
        if (search) {
            url = Constants.url + 'all_goods?search=' + search;
        } else {
            url = Constants.url + 'all_goods';
        }
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getGoodsDetail(id?: number): Observable<any> {
        const url = Constants.url + 'goods/' + id.toString();
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    addNewVendorGood(url: string, data): Observable<any> {
        return this.http.post(url, data, Constants.getAuthHeaderOption());
    }

    getGoodsCategory(): Observable<any> {
        const url = Constants.url + 'goods-category';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getWarehouseFor(vendor): Observable<any> {
        const url = Constants.url + 'vendor/' + vendor + '/warehouses';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getWarehouse(): Observable<any> {
        const url = Constants.url + 'warehouses';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getBatchUpload() {
        const url = Constants.url + 'goods/import-sample/excel';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    addGood(good: IGood): Observable<any> {
        return this.http.post(Constants.url + 'goods', good, Constants.getAuthHeaderOption());
    }

    searchGoods(url: string, param: string) {
        if (url == null || url === '') {
            url = Constants.url + 'goods' + param;
        }

        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getGoodDetailByID(id: number, withTrend = false): Observable<any> {
        const url = Constants.url + 'goods';
        let withTrendParam = '';
        if (withTrend) {
            withTrendParam = '?trend=true';
        }
        return this.http.get<any>(url + '/' + id + withTrendParam, Constants.getAuthHeaderOption()).pipe(
            map((res) => {
                    return res;
                }
            )
        );
    }

    searchTrend(id, from, to): Observable<any> {
        const url = Constants.url + 'goods';
        const withTrendParam = [];
        if (from !== undefined && from) {
            withTrendParam.push('from=' + from);
        }
        if (to !== undefined && to) {
            withTrendParam.push('to=' + to);
        }
        let p = withTrendParam.join('&');
        if (p.length) {
            p = '?'.concat(p);
        }

        return this.http.get(url + '/' + id + '/trend' + p, Constants.getAuthHeaderOption());
    }

    updateGood(url, data): Observable<any> {
        return this.http.put(url, data, Constants.getAuthHeaderOption());
    }

    deleteGood(id: number): Observable<any> {
        const url = Constants.url + 'goods';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    deleteGoods(data: object) {
        const d = {dt: data};
        return this.http.put(Constants.url + 'goods/clear', {dt: data},
            Constants.getAuthHeaderOption());
    }

    getVendors() {
        const url = Constants.url + 'users/vendors/single';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    addNewAllGood(good: FormData): Observable<any> {
        return this.http.post(Constants.url + 'all_goods', good, Constants.getAuthHeaderOption());
    }

    deletePicture(gId: number, pId: number) {
        return this.http.delete(Constants.url + 'goods/pictures/' + gId + '/' + pId, Constants.getAuthHeaderOption());
    }
}
