import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs/Observable';
import {IAllGood, ICategory, IGood} from '../interfaces/interfaces';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';


@Injectable()
export class GoodsService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    searchGoods(url: string, param: string) {
        if (url == null || url === '') {
            url = Constants.url + 'all_goods/paged' + param;
        }

        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    deleteGoods(data: object) {
        return this.http.put(Constants.url + 'all_goods/clear', {dt: data}, Constants.getAuthHeaderOption());
    }

    deleteGood(id: number): Observable<any> {
        const url = Constants.url + 'all_goods'
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    getGoodsCategory(): Observable<any> {
        const url = Constants.url + 'goods-category';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getGoodsDetail(id: number): Observable<any> {
        const url = Constants.url + 'all_goods/' + id.toString();
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    updateGoods(good, id: number): Observable<any> {
        return this.http.put(Constants.url + 'all_goods/' + id, good, Constants.getAuthHeaderOption());
    }

    approveGoods(id: number): Observable<any> {
        return this.http.put(Constants.url + 'all_goods/approve/' + id, Constants.getAuthHeaderOption());
    }

    addNewGood(good: FormData): Observable<any> {
        return this.http.post(Constants.url + 'all_goods', good, Constants.getAuthHeaderOption());
    }
}
