import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorDisplayComponent implements OnInit {

  @Input() type = 'info';
  @Input() errorMessage = '';

  constructor() { }

  ngOnInit() {
  }

}
