import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {IInternalUser} from '../interfaces/interfaces';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class InternalUserDataService {
    public url = 'api/users';

    constructor(private http: HttpClient) {
    }

    getInternalUsers(): Observable<any> {
        const url = '/src/app/shared/user.data.ts';
        return this.http.get(url, Constants.getAuthHeaderOption());
    }

    getInternalUsersByID(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id, Constants.getAuthHeaderOption());
    }

    addInternalUser(internalUser: IInternalUser): Observable<any> {
        return this.http.post(Constants.url + 'auth/internal/signup', internalUser, Constants.getAuthHeaderOption());
    }

    updateIntenalUser(internalUser: IInternalUser): Observable<any> {
        return this.http.put(this.url + '/' + internalUser.user_id, internalUser, Constants.getAuthHeaderOption());
    }

    deleteInternalUser(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id, Constants.getAuthHeaderOption());
    }
}

