import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import {AuthGuard} from './services/auth.guard';
import {LoginComponent} from './pages/login/login.component';

export const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'app', canActivate: [AuthGuard], loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule) },
  { path: 'registration', loadChildren: () => import('app/pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent },

  // {path: 'forgot-password', component: ForgotPasswordComponent},
  // {path: 'offline', component: OfflineComponent},
  // {path: 'public/faqs', component: PublicFaqsComponent,},
  // {path: 'public/feedback', component: PublicFeedbacksComponent,},
  // {path: 'activate-account', component: ActivateAccountComponent},
  // {path: 'activated', component: ActivatedComponent},
  // {path: 'activate/:activation_token', component: ActivationComponent},
  // {path: 'reset-password/:reset_token', component: ResetPasswordComponent},
  // {path: 'reset', component: PasswordResetMessageComponent},
  // {path: '', loadChildren: () => import('app/modules/home/home.module').then(m => m.HomeModule)},
  // {path: 'app', loadChildren: () => import('app/modules/modules.module').then(m => m.ModulesModule)},
  // {path: 'register', loadChildren: () => import('app/modules/register/register.module').then(m => m.RegisterModule)},
  // {path: 'vendor-registration', loadChildren: () => import('app/modules/vendors/vendor-signup.module').then(m => m.VendorSignUpModule)},
  // {path: 'internal-registration', loadChildren: () => import('app/modules/internal-users/internal.module').then(m => m.InternalModule)},
  // {path: '**', component: NotFoundComponent},
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
   // useHash: true
});
