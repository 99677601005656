import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {Feedbacks, PaginatedLog, IResponse} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';

@Injectable()
export class FeedbacksService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    createFeedback(feedback: Feedbacks): Observable<any> {
        const url = Constants.url + 'feedbacks';
        return this.http.post(url, feedback, Constants.getAuthHeaderOption());
    }

    createResponse(iresponse: IResponse): Observable<any> {
        const url = Constants.url + 'response';
        return this.http.post(url, iresponse, Constants.getAuthHeaderOption());
    }

    getFeedbackDetail(id: number): Observable<any> {
        const url = Constants.url + 'feedbacks';

        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    deleteFeedback(id: number): Observable<any> {
        const url = Constants.url + 'feedbacks';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    getPageFeedbacks(url, search): Observable<any> {
        if (url === '') {
            url = Constants.url + 'feedbacks?search=' + search;
        }
        return this.http.get(url, Constants.getAuthHeaderOption());
    }


}
