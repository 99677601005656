import {Component, Injectable, OnInit, ViewEncapsulation} from '@angular/core';
import {Constants} from '../../app.constants';
import Swal from 'sweetalert2';
import {
    ICategory,
    ICity,
    IGood,
    IGoodDynamicProperty,
    IGoodPropertyTemplate,
    IGoodsCategory,
    IState,
    ISurveyReport
} from '../../Interfaces/interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MarketSurveyService} from '../../services/market-survey.service';
import {Router} from '@angular/router';
import {SearchDataService} from '../../services/search-data.service';
import {GoodsCategoryService} from '../../services/goods-category.service';
import {GoodsService} from '../../services/goods.service';
import {VendorDataService} from '../../services/vendor-data.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@Injectable()
export class SearchComponent implements OnInit {

    type: 'quick';
    view = 'single';
    private types = ['single', 'detailed'];
    currentView = 0;
    showFilter: any = false;
    noResult = true;
    searchForm: FormGroup;
    searchForm2: FormGroup;

    good: IGood[] = [];

    states: IState[] = [];
    categories: IGoodsCategory[] = [];
    model: any = {};
    role = localStorage.getItem('userRole');
    errorMessage: string;
    goods: IGood[] = [];
    url: string;
    resultText: string;
    survey = [];

    current_page: number;
    from: number;
    last_page: number;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
    public searching = false;
    properties: IGoodPropertyTemplate[] = [];
    dynamicProperties: IGoodDynamicProperty[] = [];
    cities?: ICity[] = [];
    selectedCities: Array<string> = [];
    loadingCategory = false;
    loadingStates = false;

    constructor(private vendorDataService: VendorDataService,
                private goodsService: GoodsService,
                private categoryService: GoodsCategoryService,
                private searchDataService: SearchDataService,
                private router: Router,
                private formBuilder: FormBuilder,
                private surveyService: MarketSurveyService) {
        this.view = this.types[this.currentView];
        this.showFilter = localStorage.getItem('showFilter');
        this.getStates();
        this.getCategories();
        this.url = Constants.url;
    }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({
            searchField: ['', Validators.compose([Validators.required])],
        });

        this.searchForm2 = this.formBuilder.group({
            categoryField: [''],
            minPriceField: [''],
            maxPriceField: [''],
            vendorField: [''],
        });
    }

    get currentMarketSurvey(): ISurveyReport {
        return this.surveyService.getCurrentLocalSurvey();
    }

    changeView() {
        this.showFilter = !this.showFilter;
        localStorage.setItem('showFilter', (this.showFilter ? 'true' : ''));
    }

    doSearch() {
        const data = [];
        this.errorMessage = '';
        this.noResult = false;
        this.goods = [];
        const searchText = this.searchForm.get('searchField').value,
            category = this.searchForm2.get('categoryField').value,
            minPrice = this.searchForm2.get('minPriceField').value,
            maxPrice = this.searchForm2.get('maxPriceField').value,
            vendor = this.searchForm2.get('vendorField').value;

        if (searchText) {
            data.push('search=' + searchText);
        }
        if (category) {
            data.push('category=' + category);
        }
        if (minPrice) {
            data.push('min_price=' + minPrice);
        }
        if (maxPrice) {
            data.push('max_price=' + maxPrice);
        }
        if (vendor) {
            data.push('vendor=' + vendor);
        }

        if (this.dynamicProperties.length) {
            const dynamicQuery = JSON.stringify(this.dynamicProperties);
            data.push('category_query=' + dynamicQuery);
        }

        if (this.selectedCities.length) {
            const selectedCities = JSON.stringify(this.selectedCities);
            data.push('cities=' + selectedCities);
        }

        if (!data.length) {
            this.errorMessage = 'No search criteria made, enter any part of the name of a product to search.';
            return;
        }

        const d = data.join('&');
        this.searching = true;

        this.searchDataService.getGoodsForSearch('', d).subscribe((res: any) => {
                // console.log(res);
                const arr = ((typeof res.data) !== 'undefined' && res.data.length > 0);
                if (arr) {
                    res.data.forEach(ss => ss.selected = false);
                }
                this.goods = res.data;
                this.current_page = res.current_page;
                this.from = res.from;
                this.last_page = res.last_page;
                this.next_page_url = res.next_page_url;
                this.path = res.path;
                this.prev_page_url = res.prev_page_url;
                this.per_page = res.per_page;
                this.to = res.to;
                this.total = res.total;

                this.updateInSurveyStatus();
                this.searching = false;

                if (!this.goods.length) {
                    this.errorMessage = 'No search result found..';
                }
            }, (err) => {
                this.searching = false;
                this.errorMessage = Constants.handleError(err, this.router);
            },
        );
    }

    getStates() {
        this.loadingStates = true;
        this.vendorDataService.getStates().subscribe((states: IState[]) => {
                this.states = states;
                this.loadingStates = false;
            }, (err) => {
                this.errorMessage = Constants.handleError(err, this.router);
                this.loadingStates = false;
            },
        );
    }

    getCategories() {
        this.loadingCategory = true;
        this.categoryService.getCategories().subscribe((categories: ICategory[]) => {
                this.categories = categories;
                this.loadingCategory = false;
            }, (err) => {
                this.errorMessage = Constants.handleError(err, this.router);
                Constants.error(this.errorMessage);
                this.loadingCategory = false;
            }
        );
    }

    changeCities(id) {
        this.cities = [];
        this.selectedCities = [];
        if (id) {
            this.states.forEach((state) => {
                if (state.id.toString() === id.toString()) {
                    this.cities = state.cities;
                }
            })
        }
    }

    changeCategories() {
        this.properties = [];
        this.dynamicProperties = [];
        const v = this.searchForm2.get('categoryField').value;
        if (!v) {
            return;
        }
        this.categories.forEach((category) => {
            if (category.id.toString() === v.toString()) {
                this.properties = category.properties_template;
            }
        })
    }

    addTextDynamicProperty(property, value) {
        let index = -1, count = 0;
        // work-around to remove old property value, since there's currently no automatic way
        this.dynamicProperties.forEach((prop) => {
            if (prop.name.toString() === property.name.toString() && prop.type !== 'select') {
                index = count;
            }
            count++;
        });
        if (index > -1) {
            this.dynamicProperties.splice(index, 1);
        }

        if (value) {
            const data = {
                name: property.name,
                type: '',
                value: value
            };
            this.dynamicProperties.push(data);
        }
    }

    addCheckBoxDynamicProperty(property, isChecked) {
        let index = -1, count = 0;
        // work-around to remove old property value, since there's currently no automatic way
        this.dynamicProperties.forEach((prop) => {
            if (prop.name.toString() === property.name.toString() && prop.type === 'select'
                && prop.value.toString() === property.value.toString()) {
                index = count;
            }
            count++;
        });
        if (index > -1) {
            this.dynamicProperties.splice(index, 1);
        }

        const data = {
            name: property.name,
            type: 'select',
            value: property.value
        };

        if (isChecked) {
            this.dynamicProperties.push(data);
        }
    }

    addCheckBoxCity(id, isChecked) {
        const index = this.selectedCities.indexOf(id);
        if (index > -1) {
            this.selectedCities.splice(index, 1);
        }

        if (isChecked) {
            this.selectedCities.push(id);
        }
    }

    public updateInSurveyStatus() {
        this.goods.forEach((good) => {
            good.inSurvey = this.surveyService.hasProductInCurrentSurvey(good.id);
            good.itemInSurvey = good.inSurvey || this.surveyService.hasItemInCurrentSurvey(good.all_good_id);
            good.markup = good.inSurvey ? this.surveyService.getMarkup(good.id) : 5;
        })
    }

    removeFromSurvey(good) {
        this.surveyService.removeFromCurrentSurvey(good);
        this.updateInSurveyStatus();
        Constants.succeeded(good.detail.name + ' removed from survey list');
    }

    replaceInSurvey(good) {
        const id = good.all_good_id;
        let oldMarkup = good.markup;
        this.currentMarketSurvey.structure_.forEach((item) => {
            if (item.item.all_good_id === id) {
                oldMarkup = item.mark_up;
            }
        });
        const newMarkup = good.markup;
        if (oldMarkup !== newMarkup) {
            Swal.fire({
                title: 'Confirm Markup',
                text: 'Select markup to use between ' + oldMarkup + '% and ' + newMarkup + '% ?',
                type: 'question',
                cancelButtonText: newMarkup + '%',
                showCancelButton: true,
                confirmButtonText: oldMarkup + '%',
                showConfirmButton: true
            }).then(goOn => {
                if (goOn.value) {
                    good.markup = oldMarkup;
                }
                this.addToSurvey(good);
            });
        } else {
            this.addToSurvey(good);
        }
    }

    addToSurvey(good) {
        let markup = good.markup;
        markup = parseInt(markup, 10);
        if (isNaN(markup)) {
            Constants.error('Markup in percentage is required!');
            return;
        }
        this.surveyService.addToCurrentSurvey(good, markup);
        this.updateInSurveyStatus();
        Constants.succeeded(good.detail.name + ' successfully added to survey list');
    }

    updateMarkupAmount(good: IGood, value: string) {
        good.markup = parseInt(value, 10);
    }

}
