import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/map'
import {IFaqs, PaginatedLog} from '../interfaces/interfaces';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class FaqService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }


    getFaqs(): Observable<any> {
        return this.http.get(Constants.url + 'faqs', Constants.getAuthHeaderOption());
    }

    addFaq(faq: IFaqs): Observable<any> {
        const url = Constants.url + 'faqs';
        return this.http.post(url, faq, Constants.getAuthHeaderOption());
    }

    getFaqByID(id: number): Observable<any> {
        const url = Constants.url + 'faqs';

        return this.http.get(url + '/' + id, Constants.getAuthHeaderOption());
    }

    updateFaq(faq: IFaqs): Observable<any> {
        const url = Constants.url + 'faqs';
        return this.http.put(url + '/' + faq.id, faq, Constants.getAuthHeaderOption());
    }

    deleteFaq(id: number): Observable<any> {
        const url = Constants.url + 'faqs';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    RemoveFaq(id) {
        const url = Constants.url + 'faqs';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

    getAllFaqs(term: string): Observable<any> {
        return this.http.get(Constants.url + 'faqs?search=' + encodeURI(term), Constants.getAuthHeaderOption());

    }

}
