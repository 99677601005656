import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/do'
import {Observable} from 'rxjs/Rx';
import {AuthService} from './auth.service';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SystemLogsService {

    constructor(public http: HttpClient, private authService: AuthService) {
    }

    searchLogs(url, params): Observable<any> {
        if (url === '') {
            url = Constants.url + 'events?' + params;
        }

        return this.http.get(url, Constants.getAuthHeaderOption());

    }

    RemoveLog(id) {
        return this.http.delete(Constants.url + 'events' + '/' + id, Constants.getAuthHeaderOption());
    }

    deleteLog(id: number): Observable<any> {
        const url = Constants.url + 'events';
        return this.http.delete(url + '/' + id, Constants.getAuthHeaderOption());
    }

}
