import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {routing} from './app.routing';
import {AppSettings} from './app.settings';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './pages/errors/not-found/not-found.component';
import {AuthService} from './services/auth.service';
import {HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './services/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './pages/login/login.component';
import {DashboardService} from './services/dashboard.service';
import {UserDataService} from './services/user.service';
import {VendorDataService} from './services/vendor-data.service';
import {SurveyService} from './services/survey.service';
import {VendorGoodsService} from './services/vendor-goods.service';
import {TaskTypeService} from './services/task-type.service';
import {RolesService} from './services/roles.service';
import {GoodsService} from './services/goods.service';
import {GoodsCategoryService} from './services/goods-category.service';
import {DocumentsService} from './services/documents.service';
import {ApprovalDetailService} from './services/approval-detail.service';
import {ApprovalRequestService} from './services/approval-request.service';
import {DocumentTypeService} from './services/document-types.service';
import {FaqService} from './services/faq.service';
import {FeedbacksService} from './services/feedbacks.service';
import {GoodsSearchService} from './services/goods-search.service';
import {GradeService} from './services/grade.service';
import {InternalUserDataService} from './services/internal-user-data.service';
import {MarketSurveyService} from './services/market-survey.service';
import {MessageTemplatesService} from './services/message-templates.service';
import {MinistryManagementService} from './services/ministry-management.service';
import {NotificationsService} from './services/notifications.service';
import {PaginationsService} from './services/paginations.service';
import {PusherService} from './services/pusher.service';
import {RatingsService} from './services/ratings.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgmCoreModule} from '@agm/core';
import {UploadService} from './services/upload.service';
import {LoaderModule} from './shared/loader/loader.module';
import {CustomErrorModule} from './shared/custom-error/custom-error.module';
import {SearchComponent} from './pages/search/search.component';
import {SearchDataService} from './services/search-data.service';
import {SystemLogsService} from './services/system-logs.service';
import {ChatService} from './services/chat.service';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {TimeagoModule} from 'ngx-timeago';
import {IconsModule} from './shared/icons/icons.module';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {NotifierModule} from 'angular-notifier';
import {AppNotifierService} from './services/app-notifier.service';

@NgModule({
    declarations: [AppComponent, NotFoundComponent, LoginComponent],
    exports: [],
    imports: [BrowserAnimationsModule, BrowserModule, routing, HttpClientModule, FormsModule, ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyApVJI8RrUJg7UsWeja6ZSq7v3g-n-fWTQ'
        }),
        LoaderModule, CustomErrorModule, CKEditorModule,
        TimeagoModule.forRoot(),
        IconsModule,
        PickerModule,
        // InputEditorModule.forRoot()
    ],
    providers: [AppSettings, AuthGuard, AuthService, DashboardService, UserDataService, VendorDataService,
        SurveyService, VendorGoodsService, TaskTypeService, RolesService, GoodsService, GoodsCategoryService,
        DocumentsService, ApprovalDetailService, ApprovalRequestService, DocumentTypeService, FaqService,
        FeedbacksService, GoodsSearchService, GradeService, InternalUserDataService, MarketSurveyService,
        MessageTemplatesService, MinistryManagementService, NotificationsService, PaginationsService, PusherService,
        RatingsService, UploadService, SearchComponent, SearchDataService, SystemLogsService, ChatService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
